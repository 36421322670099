import * as React from 'react';
import { HeadFC, PageProps } from 'gatsby';
import binoculars from '../images/binoculars.png';

import '../styles/styles.scss';
import { css } from '@emotion/react';
import { Logo } from '@/parts';

const NotFoundPage = (props: PageProps) => {
    let locale;
    const pathLocale = props.location.pathname.split('/')[1];

    if (pathLocale === 'se') {
        locale = 'se';
    } else {
        locale = 'en';
    }

    const data = {
        se: {
            title: 'Sidan hittades inte',
            text: 'Du verkar ha angett en lite väl roughly right url, pröva en annan',
        },
        en: {
            title: 'Page not found',
            text: 'You seem to have entered a bit to roughly right url, try another one',
        },
    }[locale];

    return (
        <main className="container-fluid py-4">
            <div className="row justify-content-center text-center">
                <div className="col-md-6">
                    <img
                        src={binoculars}
                        css={css({
                            width: '60%',
                            marginBottom: '4rem',
                        })}
                    ></img>
                    <h1 className="h2">{data && data.title}</h1>
                    <p className="mb-3">{data && data.text}</p>
                    <Logo />
                </div>
            </div>
        </main>
    );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
